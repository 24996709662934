import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IRegisterStudent, IRegisterStudentResponse } from '../../entity/schema/register';
import { IAuthResponse } from '../../entity/schema/auth';
import {
  IFileUploadValidateResponse,
  IUploadDoc,
  IUploadDocResponse,
} from '../../entity/schema/upload-doc';
import { getSsoToken } from '../../utils/utils';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.techastraproto.aw.prototype.labs.statefarm'
    // baseUrl: 'http://localhost:8080/v1/watermit/'
  }),
  endpoints: (builder) => ({
    userAuth: builder.query<IAuthResponse, null>({
      query: () => ({
        url: `/auth`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-SF_SSO_TOKEN': getSsoToken() || '',
        },
      }),
    }),
    claimValidate: builder.mutation<IRegisterStudentResponse, IRegisterStudent>({
      query: (data: IRegisterStudent) => ({
        url: '/claim/validate',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
          'X-SF_SSO_TOKEN': getSsoToken() || '',
        },
      }),
    }),
    registerStudent: builder.mutation<IRegisterStudentResponse, IRegisterStudent>({
      query: (data: IRegisterStudent) => ({
        url: '/put-rsrc',
        method: 'PUT',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),

    uploadDoc: builder.mutation<IUploadDocResponse, IUploadDoc>({
      query: (data: IUploadDoc) => ({
        url: '/doc/upload',
        method: 'POST',
        body: data.files,
        params: {
          externalClaimId: data.externalClaimId,
          documentGroupTypeCode: data.documentGroupTypeCode,
          categoryType: data.categoryType,
        },
        headers: {
          'X-SF_SSO_TOKEN': getSsoToken() || '',
        },
      }),
    }),

    validateDoc: builder.mutation<IFileUploadValidateResponse, FormData>({
      query: (files: FormData) => ({
        url: '/doc/validate',
        method: 'POST',
        body: files,
        headers: {
          'X-SF_SSO_TOKEN': getSsoToken() || '',
        },
      }),
    }),
  }),
});

export const {
  useUserAuthQuery,
  useClaimValidateMutation,
  useRegisterStudentMutation,
  useUploadDocMutation,
  useValidateDocMutation,
} = apiSlice;
