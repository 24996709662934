import {NavigateFunction} from 'react-router-dom';
import {ResourcePage} from '../utils/const';
import {IAppError} from './error/ErrorRoute';
import {NotificationIconVariant, OneXNotification} from '../components/oneX/OneXNotification';
import React from 'react';

export const onErrorNavigate = (isError: boolean, error: any, navigate: NavigateFunction) => {

    if (isError &&
        typeof error === 'object' &&
        error != null &&
        'originalStatus' in error &&
        401 === error.originalStatus) {
        navigate(ResourcePage.error, {
            state: {
                code: error.originalStatus,
            } as IAppError,
        })
    }

    return (
        isError &&
        typeof error === 'object' &&
        error != null &&
        'originalStatus' in error &&
        422 !== error.originalStatus &&
        navigate(ResourcePage.error, {
            state: {
                code: error.originalStatus,
            } as IAppError,
        })
    );
};

export const unprocessableEntityNotifyError = (isError: boolean, error: any, id: string) => {
    return (
        isError &&
        typeof error === 'object' &&
        error != null &&
        'originalStatus' in error &&
        422 === error.originalStatus && (
            <OneXNotification
                id={'rejection-notification-' + id}
                icon={NotificationIconVariant.ERROR}
                notificationText={
                    'One or more fields entered is incorrect, please re-enter the information and try again!'
                }
            />
        )
    );
};

export const onUploadError = (isError: boolean, error: any, id: string) => {
    return (
        isError &&
        typeof error === 'object' &&
        error != null &&
        'originalStatus' in error &&
        uploadErrorInfo(error.originalStatus)
    );
};

const uploadErrorInfo = (statusCode: number): JSX.Element => {
    if (statusCode === 400 || statusCode === 403 || statusCode === 422) {
        return (
            <OneXNotification
                id={'Bad-request-error-403-400'}
                icon={NotificationIconVariant.ERROR}
                notificationText={'Invalid file, please try again'}
            />
        );
    }
    return (
        <OneXNotification
            id={'Bad-request-error-500'}
            icon={NotificationIconVariant.ERROR}
            notificationText={
                'Something went wrong, we apologize for the inconvenience, please try again'
            }
        />
    );
};
