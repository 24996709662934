import React, { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';
import { LayoutSize } from '../generic';
import { mergeClassNames } from '../../utils/utils';

export interface IOneXRow {
  id?: string;
  componentKey?: string | number | null | undefined;
  offSet?: LayoutSize;
  customClass?: string;
  style?: CSSProperties;
  onClickCallBack?: MouseEventHandler;
}

export const OneXRow: FC<IOneXRow & { children: ReactNode }> = (
  props: IOneXRow & { children: ReactNode },
): JSX.Element => {
  const { id, children, componentKey, offSet, style, customClass, onClickCallBack } = props;

  const offSetClassName: string = (offSet && '-oneX-offset-' + offSet) || '';
  const customClassName: string = customClass || '';
  const rowClassName = mergeClassNames('-oneX-row', offSetClassName, customClassName);

  return (
    <div
      id={id}
      key={componentKey}
      className={rowClassName}
      style={style}
      onClick={onClickCallBack}
    >
      {children}
    </div>
  );
};
