import React, { FC } from 'react';

interface ILabel {
  id: string;
  label: string;
  type: 'textfield' | 'dropdown';
}

export const OneXLabel: FC<ILabel> = (props: ILabel) => {
  const { id, label, type } = props;
  return (
    <label
      data-testid={id + '-input-label-test-id'}
      htmlFor={id}
      className={`-oneX-${type}__floatingLabel`}
    >
      {label}
    </label>
  );
};
