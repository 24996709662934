import React, { Component, ReactNode } from 'react';
import { NotificationIconVariant, OneXNotification } from '../components/oneX/OneXNotification';
import { OneXContainer } from '../components/oneX/OnexContainer';

interface IErrorBoundaryProps {
  children: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error: any;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false,
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    return { hasError: true, error: error.message };
  }

  public render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error)
      return (
        <OneXContainer>
          <OneXNotification
            id={'_id'}
            icon={NotificationIconVariant.ERROR}
            heading={'Technical Error'}
            notificationText={
              'Something went wrong, we apologize for the inconvenience, please try again later.'
            }
          />
        </OneXContainer>
      );
    return children;
  }
}

export default ErrorBoundary;

export const handleError = (id: string, error: any): JSX.Element => {
  if (error && error.status && error.originalStatus) {
      if (error.status === 401 || error.originalStatus === 401) {
          return (
              <>
                  <OneXNotification
                      id={id}
                      icon={NotificationIconVariant.ERROR}
                      heading={'Invalid Credentials or Session expired'}
                      notificationText={
                          'Full authentication is required to access this resource.'
                      }
                  />
                  {setTimeout(() => {
                      window.location.href = process.env.REACT_APP_HOST_B2B + '/b2b-content';
                  }, 5000)}
              </>
          );
      } else if (error.status === 403 || error.originalStatus === 403) {
          return (
              <OneXNotification
                  id={id}
                  icon={NotificationIconVariant.ERROR}
                  heading={'Access Forbidden'}
                  notificationText={"You don't have enough permissions to access this page."}
              />
          );
      } else if (error.status === 422 || error.originalStatus === 422) {
          return (
              <>

                  <OneXNotification
                      id={id}
                      icon={NotificationIconVariant.ERROR}
                      heading={'Claim Not Found'}
                      notificationText={'Claim details not found'}
                  />

              </>
          );
      } else if (error.status === 400 || error.originalStatus === 400) {
          return (
              <>
                  <OneXNotification
                      id={id}
                      icon={NotificationIconVariant.ERROR}
                      heading={'Claim Not Found'}
                      notificationText={'Invalid claim information'}
                  />

              </>
          );
      } else if (error.status === 500 || error.originalStatus === 500) {
          return (
              <OneXNotification
                  id={id}
                  icon={NotificationIconVariant.ERROR}
                  heading={'Technical Error'}
                  notificationText={
                      'Something went wrong, we apologize for the inconvenience, please try again later.'
                  }
              />
          );
      }
  }
  return (
      <OneXNotification
          id={id}
          icon={NotificationIconVariant.ERROR}
          heading={'Technical Error'}
          notificationText={
              'Something went wrong, we apologize for the inconvenience, please try again later.'
          }
      />
  );
};
