import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ResourcePage} from './utils/const';
import RegisterForm from './pages/register';
import {ErrorWaterMitigation} from './pages/error/ErrorRoute';
import {RouteNotFound} from './pages/routeNotFound/RouteNotFound';
import Root from './pages/init-config';
// import { RegisterForm } from './post-mvp/register/register-form';


// export const App = () => <RouterProvider router={MitigationRoute()} />;

// const handleErrorText = (statusCode: number) => {
//     if (statusCode === 401) {
//         return {
//             heading: 'Invalid Credentials or Session expired',
//             notificationText: 'Full authentication is required to access this resource.'
//         };
//     } else if (statusCode === 403) {
//         return {
//             heading: 'Forbidden Access',
//             notificationText: 'Full authentication is required to access this resource.'
//         };
//     }
//     return {
//         heading: 'Technical Error',
//         notificationText: 'Full authentication is required to access this resource.'
//     };
// };

export const router = () =>
    createBrowserRouter(
        [
            {
                path: ResourcePage.home,
                element: <Root/>,
                children: [
                    {
                        path: ResourcePage.register,
                        element: <RegisterForm/>,
                    },
                    {
                        path: ResourcePage.error,
                        element: <ErrorWaterMitigation/>,
                    },
                    {
                        path: ResourcePage.routeNotFound,
                        element: <RouteNotFound/>,
                    },
                ],
            },
        ],
        {
            basename: ResourcePage.tarBasePath,
            future: {
                v7_normalizeFormMethod: true,
            },
        },
    );

export const App = () => <RouterProvider router={router()}/>;

