export const adobeAnalytics = () => {
  const tagEvent = new Event('screenChange', {
    bubbles: true,
    cancelable: false,
  });

  const setDataLayer = (screenId: string) => {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.screenChannel = 'b2b-water-mitigation';
      (window as any).dataLayer.screenId = screenId;
      window.dispatchEvent(tagEvent);
    }
  };

  const docUploadCount = (screenId: string, fileCount: number) => {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.screenChannel = 'b2b-water-mitigation';
      (window as any).dataLayer.screenId = screenId;
      (window as any).dataLayer.docsUploaded = fileCount;
      window.dispatchEvent(tagEvent);
    }
  };

  const claimValidate = (screenId: string, externalClaimID: string) => {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.screenChannel = 'b2b-fire-vendor';
      (window as any).dataLayer.screenId = screenId;
      (window as any).dataLayer.externalClaimId = externalClaimID;
      window.dispatchEvent(tagEvent);
    }
  }

  return {
   //tagClaimValidate: () => setDataLayer('claim-validate'),
    tagClaimValidate: (externalClaimID: string) => claimValidate('claim-validate', externalClaimID),
    tagUploadDoc: () => setDataLayer('upload-doc'),
    tagSummaryReport: () => setDataLayer('summary-report'),
    tagDocSubmitted: (fileCount: number) => docUploadCount('submitted-doc', fileCount),
    tagErrorPage: (error = 'water-mitigation-error') => setDataLayer(error),
  };
};
