import React, { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';
import { LayoutSize } from '../generic';
import { mergeClassNames } from '../../utils/utils';

export interface IOneXColumn {
  children: ReactNode;
  componentKey?: string | number;
  offSet?: LayoutSize;
  sm?: LayoutSize;
  md?: LayoutSize;
  lg?: LayoutSize;
  xl?: LayoutSize;
  defaultSize?: LayoutSize;
  customClass?: string;
  style?: CSSProperties;
  onClickCallBack?: MouseEventHandler;
}

export const OneXColumn: FC<IOneXColumn> = (props: IOneXColumn): JSX.Element => {
  const {
    children,
    componentKey,
    offSet,
    sm,
    md,
    lg,
    xl,
    defaultSize,
    style,
    customClass,
    onClickCallBack,
  } = props;
  const offSetClassName: string = (offSet && '-oneX-offset-' + offSet) || '';
  const smClassName: string = (sm && '-oneX-col-sm-' + sm) || '';
  const mdClassName: string = (md && '-oneX-col-md-' + md) || '';
  const lgClassName: string = (lg && '-oneX-col-lg-' + lg) || '';
  const xlClassName: string = (xl && '-oneX-col-xl-' + xl) || '';
  const defaultSizeClassName: string = defaultSize ? '-oneX-col-' + defaultSize : '-oneX-col';
  const customClassName: string = customClass || '';

  const colClass = mergeClassNames(
    defaultSizeClassName,
    offSetClassName,
    smClassName,
    mdClassName,
    lgClassName,
    xlClassName,
    customClassName,
  );
  return (
    <div key={componentKey} className={colClass} style={style} onClick={onClickCallBack}>
      {children}
    </div>
  );
};
