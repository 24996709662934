import React, { CSSProperties, FC, MouseEventHandler } from 'react';
import { mergeClassNames } from '../../utils/utils';

type OneXButtonSizeType = 'extraLarge' | 'medium' | 'small'; // default to Large

const oneXButtonSizeClass = (size?: OneXButtonSizeType): string => {
  if (size === 'extraLarge') {
    return '-oneX-btn-exlarge';
  } else if (size === 'medium') {
    return '-oneX-btn-medium';
  } else if (size === 'small') {
    return '-oneX-btn-small';
  }
  return '';
};

export interface IOneXButton {
  id: string;
  label: string;
  buttonRef?: React.RefObject<HTMLButtonElement> | undefined;
  anchorRef?: React.RefObject<HTMLAnchorElement> | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  actionRole?: 'primary' | 'secondary';
  disabled?: boolean;
  buttonSize?: OneXButtonSizeType;
  onClickCallBack?: MouseEventHandler;
  isAnchorElement?: boolean;
  href?: string;
  style?: CSSProperties;
}

export const OneXButton: FC<IOneXButton> = (props: IOneXButton): JSX.Element => {
  const {
    id,
    type,
    anchorRef,
    buttonRef,
    actionRole,
    isAnchorElement,
    href,
    onClickCallBack,
    style,
    buttonSize,
    label,
    disabled,
  } = props;

  let buttonActionClassName: string =
    actionRole === 'primary' ? '-oneX-btn-primary' : '-oneX-btn-secondary';
  if (isAnchorElement) {
    buttonActionClassName = buttonActionClassName + '__anchor';
    if (disabled) {
      buttonActionClassName = mergeClassNames(
        buttonActionClassName,
        buttonActionClassName + '--disabled',
      );
    }
  }

  const defaultSizeClassName: string = oneXButtonSizeClass(buttonSize);
  const resultingClassName = mergeClassNames(
    buttonActionClassName,
    '-oneX-btn-fit-content',
    defaultSizeClassName,
  );

  return (
    <>
      {isAnchorElement ? (
        <a
          ref={anchorRef}
          id={id}
          role='button'
          href={href}
          onClick={onClickCallBack}
          target='_blank'
          style={{
            ...style,
            margin: '10px',
          }}
          className={resultingClassName}
          rel='noreferrer'
        >
          {label}
        </a>
      ) : (
        <button
          ref={buttonRef}
          id={id}
          type={type}
          className={resultingClassName}
          onClick={onClickCallBack}
          style={{
            ...style,
            margin: '10px',
          }}
          disabled={disabled}
        >
          {label}
        </button>
      )}
    </>
  );
};
