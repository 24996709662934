import React, {FC, useEffect} from 'react';
import {useLocation} from 'react-router';
import {generateKey} from '../../utils/utils';
import {NotificationIconVariant, OneXNotification} from '../../components/oneX/OneXNotification';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {OneXRow} from '../../components/oneX/OneXRow';
import {OneXButton} from '../../components/oneX/OneXButton';
import {resetValidateClaimResponse} from '../register/register-slice';
import {useAppDispatch} from '../../app/hooks';
import {useNavigate} from 'react-router-dom';
import {ResourcePage} from '../../utils/const';
import {adobeAnalytics} from '../../utils/adobeAnalytics/adobeAnalytics';

export interface IAppError {
  code: number;
}

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'originalStatus' in error;
}

export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export const ErrorWaterMitigation: FC = (): JSX.Element => {
  const location = useLocation();
  const state = (location.state as IAppError) || null;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    adobeAnalytics().tagErrorPage();
  }, []);

  const _handleErrorDisplay = (): JSX.Element => {
    if (state && state.code) {
      if (state.code === 400) {
        return (
          <OneXNotification
            id={'Bad-request-error'}
            icon={NotificationIconVariant.ERROR}
            notificationText={
              'Something went wrong, we apologize for the inconvenience, please try again'
            }
            heading={'Bad Request'}
          />
        );
      }
        if (state.code === 401) {
            return (
                <OneXNotification
                    id={'Bad-request-error'}
                    icon={NotificationIconVariant.ERROR}
                    notificationText={
                        'Full authentication is required to access this page.'
                    }
                    heading={'Invalid Credentials or Session Expired'}
                />
            );
        }
    }
    return (
      <OneXNotification
        id={'Technical-error'}
        icon={NotificationIconVariant.ERROR}
        notificationText={
          'Something went wrong, we apologize for the inconvenience, please try again'
        }
        heading={'Technical Error'}
      />
    );
  };
  return (
    <section id={'water-mitigation-error-' + generateKey()}>
      {_handleErrorDisplay()}
      <OneXRow style={{ justifyContent: 'center', paddingTop: '2vh' }}>
        <OneXButton
          id={'retry-water-mitigation-button'}
          label={'Try again'}
          type={'button'}
          actionRole={'primary'}
          onClickCallBack={() => {
            dispatch(resetValidateClaimResponse());
            navigate(ResourcePage.register);
          }}
        />
      </OneXRow>
    </section>
  );
};
