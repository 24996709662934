import React, { CSSProperties, ChangeEvent, FC, FocusEvent } from 'react';
import OneXFieldErrorState from './OneXFieldErrorState';
import { OneXLabel } from './OneXLabel';
import { OneXErrorState } from './OneXErrorState';
import { MAX_LENGTH_TEXTFIELD } from '../generic';

interface ITextField {
  name: string;
  label: string;
  type: string;
  error: string;
  value: string;
  defaultValue?: string;
  // onUpdate:Date;
  onInputChange?: (event: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void;
  onBlur?: (event: FocusEvent<HTMLElement, Element>) => void;
  onFocus?: (event: FocusEvent<HTMLElement, Element>) => void;
  title?: string;
  dataFormatter?: string;
  pattern?: string;
  max?: number;
  disable?: boolean;
  contextualHelp?: boolean;
  preventCut?: boolean;
  preventCopy?: boolean;
  preventPaste?: boolean;
  readOnly?: boolean;
  style?: CSSProperties;
}

const DatePickerIcon = (props: {disabled: boolean}) => {
  return (
      <button className='-oneX-btn-control' disabled={props.disabled} aria-label='Choose date.' type={"button"}>
          <div aria-label='datepicker' role='img' className='-oneX-icon--controls--calendar'/>
      </button>
  );
}

export const OneXFloatingTextField: FC<ITextField> = (props: ITextField): JSX.Element => {
  const {
    name,
    label,
    type,
    error,
    value,
    defaultValue,
    onInputChange,
    title,
    onBlur,
    onFocus,
    disable,
    dataFormatter,
    pattern,
    readOnly
  } = props;

  const max: number = props.max || MAX_LENGTH_TEXTFIELD,
    fieldSetClass = '-oneX-fieldset ',
    inputClass = `-oneX-textfield--floating-input`;

  return (
    <OneXErrorState id={name} error={error}>
      <fieldset className={fieldSetClass}>
        <OneXLabel id={name} label={label} type={'textfield'} />
        <input
          data-testid={name + '-input-test-id'}
          id={name}
          type={type}
          aria-invalid={error ? 'true' : 'false'}
          aria-describedby={`${name}-msg`}
          className={inputClass}
          name={name}
          value={value}
          defaultValue={defaultValue}
          maxLength={max}
          onChange={onInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
          title={title}
          onCut={(e) => e.preventDefault()}
          onCopy={(e) => e.preventDefault()}
          readOnly={readOnly}
          disabled={disable || readOnly}
          data-formatter={dataFormatter}
          pattern={pattern}
        />
    {/* { dataFormatter=== 'date' && <DatePickerIcon disabled={!!disable}/>} */}

      </fieldset>
      {error && <OneXFieldErrorState message={error} />}
    </OneXErrorState>
  );
};
