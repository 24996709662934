export const registerConstraints = {
  parentFirstName: {
    required: {
      value: true,
      message: 'First Name is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid First Name',
    },
  },
  parentLastName: {
    required: {
      value: true,
      message: 'Last name is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid Last Name',
    },
  },
  parentEmail: {
    required: {
      value: true,
      message: 'Email is a required field',
    },
    pattern: {
      value: '^\\S+@\\S+\\.\\S+$',
      message: "Please enter a valid email.",
    },
  },
  parentPhone: {
    required: {
      value: true,
      message: 'Phone number is a required field',
    },
    pattern: {
      value: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
      message: 'Please enter a 10 digit phone number.',
    },
  },
  studentfname: {
    required: {
      value: true,
      message: 'First Name is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid First Name',
    },
  },
  studentlname: {
    required: {
      value: true,
      message: 'Last name is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid Last Name',
    },
  },
  studentemail: {
    required: {
      value: true,
      message: 'Email is a required field',
    },
    pattern: {
      value: '^\\S+@\\S+\\.\\S+$',
      message: "Please enter a valid email.",
    },
  },
  studentPhone: {
    required: {
      value: true,
      message: 'Phone number is a required field',
    },
    pattern: {
      value: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
      message: 'Please enter a 10 digit phone number.',
    },
  },
};

export const summaryReportConstraints = {
categoryOfWater: {
    required: {
      value: true,
      message: 'Category of water is a required field',
    },
    pattern: {
      value: '^[1-3]$',
      message: 'Please enter a valid category of water',
    },
  },
  classOfWater: {
    required: {
      value: true,
      message: 'Class of water is a required field',
    },
    pattern: {
      value: '^[1-4]$',
      message: 'Please enter a valid class of water',
    },
  },
  daysToAchieveDryStandard: {
    required: {
      value: true,
      message: 'Days to achive is a required field',
    },

  },
  comments: {
    required: {
      value: false,
      message: 'Comments is a required field',
    },
  },
  mitigationEstimate: {
    required: {
      value: true,
      message: 'Mitigation Estimate is a required field',
    },

  },
  deductible: {
    required: {
      value: true,
      message: 'Deductible is a required field',
    },
  },
  equipmentDiscount: {
    required: {
      value: true,
      message: 'Equipment discount is a required field',
    },

  },
  equipmentTotal: {
    required: {
      value: true,
      message: 'Equipment total is a required field',
    }
  },

};

