import {RefObject, useLayoutEffect} from 'react';

export function useOneXSubscription<T extends HTMLElement>(ref: RefObject<T>) {
  useLayoutEffect(() => {
    const current = ref.current;
    if (current) {
      oneX.addElement(current);
    }

    return () => {
      if (current) {
        oneX.removeElement(current);
      }
    };
  }, [ref]);
}
