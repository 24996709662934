import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

export const RouteNotFound: FC = (): JSX.Element => {
  const location = useLocation();

  return (
    <p>
      No match for <code>{location.pathname}</code>
    </p>
  );
};
