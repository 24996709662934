export const SPACE = ' ';
export const COMMA = ',';
export const AND = '&';
export const DOLLAR_SYMBOL = '$';
export const EMPTY_STRING = '';
export const HYPHEN = '-';

export const ONE_SECOND = 1000;

export const ResourcePage = {
  tarBasePath: '/',
  home: '/',
  register: '/register',
  // uploadDoc: '/upload-doc',
  // summaryReport: '/summary-report',
  // submitted: '/submitted',
  error: '/error',
  routeNotFound: '*',
};
