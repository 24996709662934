import { configureStore } from '@reduxjs/toolkit';
import claimInfoReducer from '../pages/register/register-slice';

import { apiSlice } from '../pages/api/apiSlice';

export const store = configureStore({
  reducer: {
    claim: claimInfoReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
