import React, { createContext, FC, ReactNode, useContext, useEffect, useRef } from 'react';
import { useOneXSubscription } from '../../hooks/useOneXSubscription';
import { Typography } from './OneXTypogrpahy';

export const PreventFocusChangeNotificationContext = createContext(true);

export enum NotificationIconVariant {
  INFORMATIONAL = 'informational',
  WARNING = 'warning ',
  ERROR = 'error',
  SUCCESS = 'confirmation',
  // CHEVRON_RIGHT = 'chevron_right',
  // CHEVRON_LEFT = 'chevron',
  // CHEVRON_DOWN = 'chevron',
  // CHEVRON_UP = 'chevron'
}

interface IOneXNotification {
  id: string;
  icon: NotificationIconVariant;
  heading?: string;
  notificationText?: string;
}

export const OneXNotification: FC<{ children?: ReactNode } & IOneXNotification> = (
  props: { children?: ReactNode } & IOneXNotification,
) => {
  const { id, icon, heading, notificationText } = props;
  const preventFocusChange = useContext(PreventFocusChangeNotificationContext);
  const selfRef: React.RefObject<HTMLDivElement> = useRef(null);
  useOneXSubscription(selfRef);

  useEffect(() => {
    if (!preventFocusChange && selfRef.current) {
      selfRef.current.focus();
    }
  }, [selfRef, preventFocusChange]);

  return (
    <section id={'notification-section-' + id}>
      {heading && (
        <Typography
          id={'error-route-heading-' + id}
          style={{ paddingTop: '1.5vh', paddingBottom: '1.5vh' }}
          heading={'h4'}
        >
          {heading}
        </Typography>
      )}
      <div
        ref={selfRef}
        key={'onex-notification-' + id}
        aria-live='assertive'
        tabIndex={-1}
        className='-oneX-notification'
      >
        <section className={`-oneX-notification__icon -oneX-notification--${icon.trim()}`}>
          <span className={`-oneX-icon--${icon.trim()}`} aria-label={`${icon.trim()}`} role='img' />
        </section>
        <section className='-oneX-notification__text'>{notificationText || props.children}</section>
      </div>
    </section>
  );
};
