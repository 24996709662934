import axios,{AxiosResponse} from "axios";

export function identityFn<T>(entry: T): T {
  return entry;
}

export function mergeClassNames(
  defaultClass: string,
  ...otherClasses: ReadonlyArray<string | null | undefined>
) {
  const concatClasses = otherClasses.filter(identityFn).join(' ');
  return concatClasses ? `${defaultClass} ${concatClasses}` : defaultClass;
}

export const generateKey = () => {
  return;
};

export const getSsoToken = (): string | null => {
  const cookies = document.cookie.match(/sf-icp-sso=([^;]*)/);
  // if (cookies !== null) return cookies[1];
  return '-nX9kJNWUw-vAh4-S9Pe0DcZRaw.*AAJTSQACMDIAAlNLABw1ZGpyWlQ0bXVyRTBleWNBR3o3alg0MEM3a1E9AAR0eXBlAANDVFMAAlMxAAIwMQ..*';
};

export const saAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HOST_B2B,
  responseType: "json",
  headers: {
      "Content-Type": "application/json",
      "X-SF_SSO_TOKEN":getSsoToken() || 'undefined'
  },
});

export const postRequest = <I, D>(uri: string, data: I, params?: URLSearchParams): Promise<AxiosResponse<D>> => {
  return saAxiosInstance.post(uri, data, {params: params});
};

export const putRequest = <I, D>(uri: string, data: I, params?: URLSearchParams): Promise<AxiosResponse<D>> => {
  return saAxiosInstance.put(uri, data, {params: params});
};

export const getRequest = <D>(uri: string, params?: URLSearchParams): Promise<AxiosResponse<D>> => {
  return saAxiosInstance.get(uri, {params: params});
};

const nf = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  // roundingIncrement: 5,
});

export const toCurrency = (number:number) => nf.format(number);

export const isNumericString = (str: string): boolean => {
  if (typeof str != "string") return false 
  return !!str && !isNaN(parseFloat(str))
}
