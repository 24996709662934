import React, {FC, useCallback, useEffect, useId, useRef, useState} from 'react';
import {OneXColumn} from '../../components/oneX/OneXColumn';
import {OneXRow} from '../../components/oneX/OneXRow';
import {Typography} from '../../components/oneX/OneXTypogrpahy';
import {useOneXSubscription} from '../../hooks/useOneXSubscription';
import {useForm} from '../../hooks/useForm';
import {OneXFloatingTextField} from '../../components/oneX/OneXFloatingTextField';
import {OneXButton} from '../../components/oneX/OneXButton';
import {IRegisterStudent, IRegisterStudentResponse} from '../../entity/schema/register';
import {registerConstraints} from '../../utils/formValidator/schema';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {useRegisterStudentMutation, useUserAuthQuery} from '../api/apiSlice';
import {RootState} from '../../app/store';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {OneXLoader} from '../../components/oneX/OneXLoader';
import {onErrorNavigate, unprocessableEntityNotifyError} from '../helper';
import { setRegStudentInfo, setExternalClaimId } from './register-slice';
// import { DateInput } from '../../entity';

export function nonEmptyValues(value: string) {
    if (value.length>0) return value;
    return value;
}

export function onlyNumericValues(value: string) {
    if (/^[0-9]+$/.test(value)) return value;
    return value.replace(/[^0-9]+$/, '');
}

export function onlyNumericCurrencyValues(value: string) {
    if (/^[0-9]+$/.test(value)) return value;
    return value.replace(/[^0-9.]+$/, '');
}

export function nameSnFun(value: string) {
    if (/^[a-zA-Z ]+$/.test(value)) return value;
    return value.replace(/[<>^!%+=$?{}]+/g, '');
}

export function claimNumberSnFun(value: string) {
    if (/^[a-zA-Z0-9-]+$/.test(value)) return value;
    return value.replace(/[^a-zA-Z0-9-]+$/, '');
}

export function dateOfLossSnFun(value: string) {
    const dateOfLoss = value.replace(/[^\d]/g, '');
    const dateOfLossLength = dateOfLoss?.length;
    if (dateOfLossLength < 2) return dateOfLoss;
    if (dateOfLossLength < 4) {
        return `${dateOfLoss.slice(0, 3)}-${dateOfLoss.slice(3)}`;
    }
    return `${dateOfLoss.slice(0, 2)}-${dateOfLoss.slice(2, 4)}-${dateOfLoss.slice(4, 8)}`;
}

export const RegisterForm: FC = (): JSX.Element => {
    const {isError: isAuthError, error: authError} = useUserAuthQuery(null);

    const [validDate, setValidDate] = useState(false);
    const [date, setDate] = useState('');
    const [nextDisabled, setNextDisabled] = useState(true);
    const [dateNotUpdatedYet, setDateNotUpdatedYet] = useState(true);

    const id = useId();
    const navigate = useNavigate();
    const registrationData = useAppSelector((state) => state.claim);
    const dispatch = useAppDispatch();
    const selfRef = useRef<HTMLFormElement>(null);
    const registerStudentResponse: IRegisterStudentResponse | undefined = useSelector(
        (state: RootState) => state.claim.response,
    );
    useOneXSubscription(selfRef);

    // useEffect(() => {
    //     dispatch(
    //         setFileUploadStatus({
    //             isFileUploaded: false,
    //         }),
    //     );
    //     adobeAnalytics().tagClaimValidate(registerStudentResponse?.externalClaimID || '');
    // }, [registerStudentResponse]);

    const handleOnSubmit = (value: IRegisterStudent) => {
        registerStudent({
            ...value,
            parentFirstName: value.parentFirstName.trim(),
            parentLastName: value.parentLastName.trim(),
            parentEmail: value.parentEmail,
            parentPhone: value.parentPhone,
            studentfname: value.studentfname.trim(),
            studentlname: value.studentlname.trim(),
            studentemail: value.studentemail,
            studentPhone: value.studentPhone,
            age: value.age,
            studentGrade: value.studentGrade,
            schoolname: value.schoolname,
            studentVideoConsent: value.studentVideoConsent,
            studentAdditionalInfo: value.studentAdditionalInfo,
            studentLunch: value.studentLunch,
            studentFoodAllergy: value.studentFoodAllergy,
        })
            .unwrap()
            .then((res: IRegisterStudentResponse) => {
                dispatch(
                    setRegStudentInfo({
                        studentInfo: value,
                    }),
                );
                // dispatch(
                //     setExternalClaimId({
                //         response: res,
                //     }),
                // );
            });

        console.log('Registering student');
    };
    const [registerStudent, {data: externalClaimId, isLoading, isSuccess, isError, error}] =
        useRegisterStudentMutation();
    const {dirty, handleSubmit, handleBlur, handleChangeAndValidate, data, errors, disable} =
        useForm<IRegisterStudent>({
            initialValues: registrationData.info || ({} as IRegisterStudent),
            validations: registerConstraints,
            onSubmit: () => handleOnSubmit(data),
        });

    // useEffect(() => {
    //     if (registerStudentResponse) {
    //
    //         // navigate(ResourcePage.summaryReport);
    //     }
    // }, [navigate, registerStudentResponse]);

    // const updateDateOfLoss = useCallback((newDate: string) => {
    //     if (newDate.length > 10) {
    //         newDate = newDate.substring(0, 10);
    //     }
    //     const formattedDate = moment(newDate, 'MM-DD-YYYY', true);
    //     setDate(newDate);
    //     setValidDate(formattedDate.isValid() && formattedDate.isSameOrBefore());
    //     if (newDate.length > 0) {
    //         setDateNotUpdatedYet(false);
    //         //errors.dateOfLoss = "Date Of Loss is a Required Field";
    //     }
    //     // else if (!newDate) {
    //     //   errors.dateOfLoss = "Date Of Loss is a Required Field";
    //     // }
    // }, []);

    // useEffect(() => {
    //     setNextDisabled(!validDate);
    // }, [validDate]);

    //showing valid error message for Date of loss

    return (
        <>
            <br/>
            {/*{onErrorNavigate(isError || isAuthError, error || authError, navigate)}*/}
            <Typography id={'-info-header-primary'} heading={'h3'} style={{paddingTop: '1.5vh'}}>
                {'Join us at the Tech Astra Summit 2024'}
            </Typography>
            {/*<Typography id={'-header-secondary'} body={'primary'}>*/}
            {/*    {"Join us at the Tech Astra Summit 2024."}*/}
            {/*</Typography>*/}
            <br/>
            <Typography
                id={'search-claim-customer-info-title'}
                heading={'h5'}
                style={{paddingTop: '1vh'}}
            >
                {'Parent Information'}
            </Typography>
            <br/>
            {unprocessableEntityNotifyError(isError, error, id)}
            <form ref={selfRef} onSubmit={handleSubmit} noValidate>
                <OneXRow>
                    <OneXColumn
                        defaultSize={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        customClass={'mp-1'}
                        style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                            data-testid={'parent-fn-input'}
                            name={'parentFirstName'}
                            value={data.parentFirstName || ''}
                            label={'First Name'}
                            type={'string'}
                            // max={11}
                            // max={9}
                            onBlur={handleBlur('parentFirstName')}
                            title={'Enter first name'}
                            error={(dirty.parentFirstName && errors.parentFirstName) || ''}
                            onInputChange={handleChangeAndValidate<string>('parentFirstName', (value) =>
                              nonEmptyValues(value),
                            )}
                            disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                        defaultSize={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        customClass={'mp-1'}
                        style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                            data-testid={'parent-ln-input'}
                            name={'parentLastName'}
                            value={data.parentLastName || ''}
                            label={'Last Name'}
                            type={'string'}
                            // max={5}
                            error={(dirty.parentLastName && errors.parentLastName) || ''}
                            onBlur={handleBlur('parentLastName')}
                            onInputChange={handleChangeAndValidate<string>('parentLastName', (value) =>
                              nonEmptyValues(value),
                            )}
                            disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                        defaultSize={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        customClass={'mp-1'}
                        style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                            data-testid={'parent-email-input'}
                            name={'parentEmail'}
                            value={data.parentEmail || ''}
                            label={'Email'}
                            type={'text'}
                            error={(dirty.parentEmail && errors.parentEmail) || ''}
                            onInputChange={handleChangeAndValidate<string>('parentEmail', (value) =>
                              nonEmptyValues(value),
                            )}
                            onBlur={handleBlur('parentEmail')}
                            disable={isLoading}
                        />
                        {/* <OneXNotification
                           id={'file-notification-'}
                           icon={NotificationIconVariant.ERROR}
                           notificationText={'Please enter at least the first 3 characters of the policyholders last name or organization name'}
                        />                     */}
                       {/*<div  className="-oneX-textarea-sensitiveText">Please enter a valid email address.</div>*/}
                    </OneXColumn>
                    <OneXColumn
                        defaultSize={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        customClass={'mp-1'}
                        style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                            data-testid={'parent-phone-input'}
                            name={'parentPhone'}
                            value={data.parentPhone || ''}
                            label={'Phone Number'}
                            type={'text'}
                            max={12}
                            dataFormatter={'phone'}
                            onBlur={handleBlur('parentPhone')}
                            title={'Phone Number'}
                            error={(dirty.parentPhone && errors.parentPhone) || ''}
                            onInputChange={handleChangeAndValidate<string>('parentPhone', (value) =>
                                nonEmptyValues(value),
                            )}
                            disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <br/>
                <Typography
                  id={'search-claim-customer-info-title'}
                  heading={'h5'}
                  style={{paddingTop: '1vh'}}
                >
                    {'Student Information'}
                </Typography>
                <br/>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-fn-input'}
                          name={'studentfname'}
                          value={data.studentfname || ''}
                          label={'First Name'}
                          type={'string'}
                          // max={11}
                          // max={9}
                          onBlur={handleBlur('studentfname')}
                          title={'Enter first name'}
                          error={(dirty.studentfname && errors.studentfname) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentfname', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-ln-input'}
                          name={'studentlname'}
                          value={data.studentlname || ''}
                          label={'Last Name'}
                          type={'string'}
                          // max={5}
                          error={(dirty.studentlname && errors.studentlname) || ''}
                          onBlur={handleBlur('studentlname')}
                          onInputChange={handleChangeAndValidate<string>('studentlname', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-email-input'}
                          name={'studentemail'}
                          value={data.studentemail || ''}
                          label={'Email'}
                          type={'text'}
                          error={(dirty.studentemail && errors.studentemail) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentemail', (value) =>
                            nonEmptyValues(value),
                          )}
                          onBlur={handleBlur('studentemail')}
                          disable={isLoading}
                        />
                        {/* <OneXNotification
                           id={'file-notification-'}
                           icon={NotificationIconVariant.ERROR}
                           notificationText={'Please enter at least the first 3 characters of the policyholders last name or organization name'}
                        />                     */}
                        {/*<div  className="-oneX-textarea-sensitiveText">Please enter a valid email address.</div>*/}
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-phone-input'}
                          name={'studentPhone'}
                          value={data.studentPhone || ''}
                          label={'Phone Number'}
                          type={'text'}
                          max={12}
                          dataFormatter={'phone'}
                          onBlur={handleBlur('studentPhone')}
                          title={'Phone Number'}
                          error={(dirty.studentPhone && errors.studentPhone) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentPhone', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-age-input'}
                          name={'age'}
                          value={data.age || ''}
                          label={'Age'}
                          type={'tel'}
                          max={2}
                          // max={9}
                          onBlur={handleBlur('age')}
                          title={'Enter student age'}
                          error={(dirty.age && errors.age) || ''}
                          onInputChange={handleChangeAndValidate<string>('age', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-grade-input'}
                          name={'studentGrade'}
                          value={data.studentGrade || ''}
                          label={'School Grade'}
                          type={'text'}
                          // max={5}
                          error={(dirty.studentGrade && errors.studentGrade) || ''}
                          onBlur={handleBlur('studentGrade')}
                          onInputChange={handleChangeAndValidate<string>('studentGrade', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-school-input'}
                          name={'schoolname'}
                          value={data.schoolname || ''}
                          label={'School Name'}
                          type={'text'}
                          error={(dirty.schoolname && errors.schoolname) || ''}
                          onInputChange={handleChangeAndValidate<string>('schoolname', (value) =>
                            nonEmptyValues(value),
                          )}
                          onBlur={handleBlur('schoolname')}
                          disable={isLoading}
                        />
                        {/* <OneXNotification
                           id={'file-notification-'}
                           icon={NotificationIconVariant.ERROR}
                           notificationText={'Please enter at least the first 3 characters of the policyholders last name or organization name'}
                        />                     */}
                        {/*<div  className="-oneX-textarea-sensitiveText">Please enter a valid email address.</div>*/}
                    </OneXColumn>
                </OneXRow>

                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-lunch-input'}
                          name={'studentLunch'}
                          value={data.studentLunch || ''}
                          label={'Lunch'}
                          type={'string'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentLunch')}
                          title={'Lunch'}
                          error={(dirty.studentLunch && errors.studentLunch) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentLunch', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-allergy-input'}
                          name={'foodAllergies'}
                          value={data.studentFoodAllergy || ''}
                          label={'Food Allergies'}
                          type={'text'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentFoodAllergy')}
                          title={'Food Allergies'}
                          error={(dirty.studentFoodAllergy && errors.studentFoodAllergy) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentFoodAllergy', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-video-input'}
                          name={'studentVideoConsent'}
                          value={data.studentVideoConsent || ''}
                          label={'Video Consent'}
                          type={'text'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentVideoConsent')}
                          title={'Video Consent'}
                          error={(dirty.studentVideoConsent && errors.studentVideoConsent) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentVideoConsent', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'additional-details-input'}
                          name={'studentAdditionalInfo'}
                          value={data.studentAdditionalInfo || ''}
                          label={'Additional Details'}
                          type={'text'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentAdditionalInfo')}
                          title={'Additional Details'}
                          error={(dirty.studentAdditionalInfo && errors.studentAdditionalInfo) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentAdditionalInfo', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <OneXRow style={{justifyContent: 'center', paddingTop: '2vh'}}>
                    <OneXButton
                        id={'register-student-button'}
                        label={'Register'}
                        type={'submit'}
                        actionRole={'primary'}
                        disabled={disable || isLoading}
                    />
                </OneXRow>
                {isLoading && <OneXLoader key={'notification-loader-' + id} show={isLoading}/>}
            </form>
        </>
    );
};