import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { OneXContainer } from '../../components/oneX/OnexContainer';
import { ResourcePage } from '../../utils/const';
import ErrorBoundary from '../ErrorBoundary';

export const Root = (): JSX.Element => {
  return (
    <OneXContainer>
      <ErrorBoundary>
        <Navigate to={ResourcePage.register} />
        <Outlet />
      </ErrorBoundary>
    </OneXContainer>
  );
};
