import React, { CSSProperties, FC, ReactNode } from 'react';
import { mergeClassNames } from '../../utils/utils';

const ONEX_HEADING_CLASS = '-oneX-heading--';
const ONEX_BODY = '-oneX-body--';
// const ONEX_FONT_WEIGHT_CLASS = '-oneX-font--';
// const ONEX_ITALIC = '-italic';
const ONEX_COLOR_VARIANT = '-oneX-typography-';

type oneXWeightType = 'none' | 'regular' | 'medium' | 'semiBold' | 'bold';
type oneXHeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type oneXBodyType = 'intro' | 'primary' | 'secondary' | 'tertiary' | 'footnote';
type oneXColorVariant = 'variant1' | 'variant2' | 'variant3' | 'variant4';

interface IOneXTypography {
  id: string;
  style?: CSSProperties;
  heading?: oneXHeadingType;
  body?: oneXBodyType;
  weight?: oneXWeightType;
  italic?: boolean;
  colorVariant?: oneXColorVariant;
  customClass?: string;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  children?: ReactNode;
}

export const Typography: FC<IOneXTypography> = (props: IOneXTypography): JSX.Element => {
  const { id, heading, body, colorVariant, style, customClass, onMouseOver, onMouseLeave } = props;
  const headingClass: string = (heading && ONEX_HEADING_CLASS + heading) || '';
  const bodyClass: string = (body && ONEX_BODY + body) || '';
  const colorVariantClass: string = (colorVariant && ONEX_COLOR_VARIANT + colorVariant) || '';

  let className = customClass || '';

  if (heading && headingClass) {
    className = headingClass;
  } else if (body && bodyClass) {
    className = bodyClass;
  }

  const typographyClass = mergeClassNames(className, colorVariantClass);
  return (
    <div
      data-testid={'test-' + id}
      id={id}
      className={typographyClass}
      style={style}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {props.children}
    </div>
  );
};
