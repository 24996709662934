import React, { FC } from 'react';
import { OneXRow } from './OneXRow';

interface IOneXLoader {
  loaderMessage?: string;
  show?: boolean;
}

export const OneXLoader: FC<IOneXLoader> = (props: IOneXLoader) => {
  let displayStyle = 'none';
  if (props.show) {
    displayStyle = 'block';
  }
  return (
    <OneXRow style={{ justifyContent: 'center' }}>
      <div className='-oneX-page-loader' tabIndex={-1} style={{ display: displayStyle }}>
        <h2 className='-oneX-loading-message'>{props.loaderMessage}</h2>
        <div className='-oneX-loader-icon' aria-hidden='true' />
        <div className='-oneX-loader-div' aria-hidden='true' style={{ display: displayStyle }}>
          <svg className='-oneX-small' viewBox='0 0 64 64'>
            <circle cx='32' cy='32' r='28.8'></circle>
          </svg>
          <svg className='-oneX-medium' viewBox='0 0 80 80'>
            <circle cx='40' cy='40' r='36'></circle>
          </svg>
          <svg className='-oneX-large' viewBox='0 0 100 100'>
            <circle cx='50' cy='50' r='45'></circle>
          </svg>
        </div>
      </div>
    </OneXRow>
  );
};
